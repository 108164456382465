import * as React from 'react';

import { PrimaryButtonLink } from '@circleci/compass';
import getConfig from 'next/config';
import { FormattedMessage } from 'react-intl';

import * as screenSizes from '~/components/shared/screenSizes';
import styled from '~/styled';

import { UseTranslatedHref } from '../shared/Link';

export const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 16px 0 48px;
  @media screen and (min-width: ${screenSizes.small}) {
    padding: 70px 0;
  }
`;

export const Header = styled.h1(({ theme }) => {
  return `
    font-size: ${theme.fontSizes.fontsize32};
    line-height: ${theme.lineHeights.lineheight40};
    font-weight: ${theme.fontWeights.bold};
    margin: 0;
  `;
});

export const DescriptionWrapper = styled.div(({ theme }) => {
  return `
    margin: ${theme.space.space16} 0;
    @media screen and (min-width: ${screenSizes.small}) {
      margin-bottom: ${theme.space.space24};
    }
    p {
      margin: 0;
      font-size: ${theme.fontSizes.fontsize16}px;
      line-height: ${theme.fontSizes.fontsize24};
    }
  `;
});

interface GitHubButtonProps {
  primaryBtn: string;
}

const GitHubButton = ({ primaryBtn }: GitHubButtonProps) => {
  const {
    publicRuntimeConfig: { hostname },
  } = getConfig();

  return (
    <PrimaryButtonLink
      href={UseTranslatedHref({
        id: `https://${hostname}/github-login/`,
        defaultMessage: `https://${hostname}/github-login/`,
      })}
    >
      <FormattedMessage id={primaryBtn} />
    </PrimaryButtonLink>
  );
};

const Hero = () => {
  const i18nIdPrefix = `vcsAuthorized-enterprise.hero`;
  return (
    <HeroSection>
      <Header>
        <FormattedMessage id={`${i18nIdPrefix}.header`} />
      </Header>
      <DescriptionWrapper>
        <FormattedMessage
          id={`${i18nIdPrefix}.paragraph`}
          tagName='div'
        />
      </DescriptionWrapper>
      <GitHubButton primaryBtn={`${i18nIdPrefix}.primaryBtn`} />
    </HeroSection>
  );
};

export default Hero;
